$(document).ready(function () {
    var width_onload = $(window).width();
    var newwidth = width_onload - 20;
    $('body').css({ 'width': newwidth });
    $(window).resize(function () {
        var width = $(window).width();
        var resizewidth = width - 20;
        $('body').css({ 'width': resizewidth });
        if (width_onload != $(window).width()) {
            //if(width < 1000)
            //{
            window.location.href = window.location.href;
            width_onload = $(window).width();
            delete width_onload;
            //}
        }
    });
});